//
// Audio Recording/Editing
//

export function initSegmentRecordAndEdit(container) {
  const uiContainer = container
  const uiZoomOut = document.querySelector('#control-zoom-out')
  const uiZoomIn = document.querySelector('#control-zoom-in')
  const uiPlay = document.querySelector('#control-playpause')
  const uiCancel = document.querySelector('#control-cancel')
  const uiAddTrim = document.querySelector('#control-add-trim')
  const uiRemoveTrims = document.querySelector('#control-remove-trim')
  const inputTimings = document.querySelector('input#timings')
  let defaultRegionId = null
  let playbackInterval = null
  let tmpRegion = null
  let zoomLevel = 0

  // Setup
  inputTimings.value = JSON.stringify([])

  // Waveforms
  const wavesurfer = window.WaveSurferSpooler.create({
    container: '#control-waveform',
    cursorColor: '#000',
    cursorWidth: 2,
    height: 180,
    waveColor: '#3E8BB7',
    progressColor: '#305677',
    plugins: [
      window.RegionsPlugin.create({}),
      window.TimelinePlugin.create({
        container: '#control-timeline',
        formatTimeCallback: (time) => {
          return window.secToTimestamp(time)
        }
      }),
    ],
    responsive: true,
  })

  // Math stuff for region timings
  const calculateRegionTimings = () => {
    const defaultRegion = []
    const outputTimings = []
    let allRegions = []
    let trimRegions = []

    // arrange all regions
    for (let i in wavesurfer.regions.list) {
      const r = wavesurfer.regions.list[i]
      if (r.id === defaultRegionId) {
        defaultRegion.push(r.start, r.end)
      } else {
        trimRegions.push([r.start, r.end])
      }
    }

    // sort trims by start times
    if (trimRegions.length > 0) {
      const tmp = []

      // sort by start times
      trimRegions.sort((a, b) => a[0] - b[0])

      // iterate through and combine overlaps
      for (let i of trimRegions) {
        const prev = tmp.at(-1)
        if (!tmp.length || prev[1] < i[0]) {
          tmp.push(i)
        } else {
          prev[1] = Math.max(prev[1], i[1])
        }
      }

      // flatten
      trimRegions = tmp.flat()

      // if the first trim ends before the main region begins
      if (trimRegions[1] <= defaultRegion[0]) {
        trimRegions = trimRegions.slice(2, trimRegions.length)
      }

      // once more, in case there is an overlap the starting point
      if (trimRegions[0] <= defaultRegion[0] && trimRegions[1] > defaultRegion[0]) {
        trimRegions.shift()
      } else {
        // add the main starting point
        trimRegions.unshift(defaultRegion[0])
      }

      // if the last trim starts and ends after the main region ends
      if (trimRegions.at(-2) >= defaultRegion[1]) {
        trimRegions = trimRegions.slice(0, -2)
      }

      // once more, in case there is an overlap at the ending point
      if (trimRegions.at(-1) >= defaultRegion[1] && trimRegions.at(-2) < defaultRegion[1]) {
        trimRegions.pop()
      } else {
        // add the main ending point
        trimRegions.push(defaultRegion[1])
      }

      // push all the timings into the main array
      allRegions = Array.prototype.concat([], trimRegions)
    } else {
      // no trims, push the main range region start/end
      allRegions = Array.prototype.concat([], defaultRegion)
    }

    // split the in/out markers array into sub-arrays
    const size = 2
    for (let i = 0; i < allRegions.length; i += size) {
      const chunk = allRegions.slice(i, i + size)
      outputTimings.push(chunk)
    }

    // hidden inputs
    inputTimings.value = JSON.stringify(outputTimings)
  }

  // main start/end region
  const addRangeRegion = (timeStart = 0, timeEnd = null) => {
    const durationTotal = wavesurfer.getDuration()

    const region = wavesurfer.addRegion({
      start: timeStart ?? 0,
      end: timeEnd ?? durationTotal,
      color: 'rgba(48, 86, 119, 0.3)',
      loop: false,
    })

    defaultRegionId = region.id
  }

  // events
  wavesurfer.on('region-created', window.debounce(() => {
    calculateRegionTimings()
  }, 200))

  wavesurfer.on('region-updated', window.debounce(() => {
    calculateRegionTimings()
  }, 50))

  wavesurfer.on('region-in', e => {
    if (e.id !== defaultRegionId) {
      wavesurfer.play(e.end)
    }
  })

  // init
  wavesurfer.on('ready', function () {
    addRangeRegion()

    uiContainer.setAttribute('data-recording', 'false')

    document.getElementById("next_button").style.opacity = 100
    document.getElementById("next_button").disabled = false
  })

  // playback
  wavesurfer.on('play', () => {
    const defaultRegion = wavesurfer.regions.list[defaultRegionId]

    uiPlay.setAttribute('data-playing', 'true')

    // stop playback outside of the selected start/end range
    playbackInterval = setInterval(() => {
      const now = wavesurfer.getCurrentTime()
      if (now >= defaultRegion.end) {
        wavesurfer.pause()
        return
      }
    }, 25);
  })

  wavesurfer.on('pause', () => {
    uiPlay.setAttribute('data-playing', 'false')
    clearInterval(playbackInterval)
  })

  // controls
  if (uiZoomOut) {
    uiZoomOut.addEventListener('click', () => {
      zoomLevel = zoomLevel - 50
      wavesurfer.zoom(zoomLevel)
    })
  }
  if (uiZoomIn) {
    uiZoomIn.addEventListener('click', () => {
      zoomLevel = zoomLevel + 50
      wavesurfer.zoom(zoomLevel)
    })
  }

  uiPlay.addEventListener('click', () => {
    const defaultRegion = wavesurfer.regions.list[defaultRegionId]

    if (wavesurfer.isPlaying()) {
      wavesurfer.pause()
    } else {
      const now = wavesurfer.getCurrentTime()
      if (now < defaultRegion.start) {
        wavesurfer.play(defaultRegion.start)
      } else {
        wavesurfer.play()
      }
    }
  })

  if (uiCancel) {
    uiCancel.addEventListener('click', () => {
      window.location.reload()
    })
  }

  if (uiAddTrim && uiRemoveTrims) {
    uiAddTrim.addEventListener('click', () => {
      const now = wavesurfer.getCurrentTime()
      wavesurfer.addRegion({
        start: now,
        end: now + 0.2,
        color: 'rgba(255, 40, 70, 0.3)',
        loop: false,
        minLength: 0.1,
      })
    })

    uiRemoveTrims.addEventListener('click', () => {
      const defaultRegion = wavesurfer.regions.list[defaultRegionId]
      inputTimings.value = ''
      wavesurfer.clearRegions()
      addRangeRegion(defaultRegion.start, defaultRegion.end)
    })
  }

  // keyboard shortcuts
  document.addEventListener('keyup', (e) => {
    if (e.code.includes('Space')) {
      // spacebar: play/pause
      e.preventDefault()
      if (wavesurfer.isPlaying()) {
        wavesurfer.pause()
      } else {
        wavesurfer.play()
      }
    } else if (e.key === 'i') {
      // i: add region
      const now = wavesurfer.getCurrentTime()
      tmpRegion = null
      tmpRegion = wavesurfer.addRegion({
        start: now,
        end: now + 0.2,
        color: 'rgba(255, 40, 70, 0.3)',
        loop: false,
        minLength: 0.1,
      })
    } else if (e.key === 'o' && tmpRegion) {
      // o: end region
      for (let i in wavesurfer.regions.list) {
        const r = wavesurfer.regions.list[i]
        if (r.id === tmpRegion.id) {
          r.update({
            end: wavesurfer.getCurrentTime()
          })
        }
      }
    } else if (e.key === 'ArrowLeft') {
      // left arrow: rewind
      wavesurfer.skipBackward(5)
    } else if (e.key === 'ArrowRight') {
      // right arrow: fast forward
      wavesurfer.skipForward(5)
    } else if (e.key === 'ArrowUp') {
      // up arrow: zoom in
      zoomLevel = zoomLevel + 50
      wavesurfer.zoom(zoomLevel)
    } else if (e.key === 'ArrowDown') {
      // down arrow: zoom out
      zoomLevel = zoomLevel - 50
      wavesurfer.zoom(zoomLevel)
    }
  })

  // init
  window.addEventListener('uppyToWavesurfer', e => {
    wavesurfer.load(e.detail.path)
  }, false)

  // load audio directly, if present
  if (uiContainer.dataset.audio) {
    wavesurfer.load(uiContainer.dataset.audio)
  }
}