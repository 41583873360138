import { Controller } from '@hotwired/stimulus'
import { uppyInstance, uploadedFileData } from '../uppy'
import { nanoid } from 'nanoid'

const HIDDEN_FIELD_NAME = `organization[segments_attributes][${nanoid()}][track]`

export default class extends Controller {
  static targets = [ 'input' ]
  static values = { types: Array, server: String }

  connect() {
    this.uppy = this.createUppy()
  }

  disconnect() {
    this.uppy.close()
  }

  createUppy() {
    const uppy = uppyInstance({
        id: this.inputTarget.id,
        types: this.typesValue,
        server: this.serverValue,
      })
      .use(Uppy.Dashboard, {
        target: this.inputTarget.parentNode,
        inline: true,
        height: 300,
        width: '100%',
        replaceTargetContent: true,
        proudlyDisplayPoweredByUppy: true,
        locale: {
          strings: {
            uploadComplete: 'Files ready! Click NEXT to continue.',
          }
        },
      })

    const librarySelect = document.querySelector('#library_id')
    const nextButton = document.querySelector('#next_button')

    const hiddenField = document.createElement('input')
    hiddenField.type = 'hidden'
    hiddenField.name = HIDDEN_FIELD_NAME
    hiddenField.value = ''
    this.element.appendChild(hiddenField)

    librarySelect.addEventListener('change', (e) => {
      const hiddenField = document.querySelector(`[name="${HIDDEN_FIELD_NAME}"]`)
      if (e.target.value !== '' && hiddenField && hiddenField.value !== '') {
        nextButton.disabled = false
      } else {
        nextButton.disabled = true
      }
    })

    uppy.on('complete', () => {
      const output = []

      uppy.getFiles().forEach(file => {
        // eh, just match any 200-level status code
        if (file.response.status >= 200 && file.response.status < 300) {
          const fileData = uploadedFileData(file, null, this.serverValue)
          output.push(JSON.parse(fileData))
        }
      })

      hiddenField.value = JSON.stringify(output)

      if (librarySelect.value !== '') {
        nextButton.disabled = false
      }
    })

    return uppy
  }
}
