import { Controller } from '@hotwired/stimulus'
import { uppyInstance, uploadedFileData } from '../uppy'

export default class extends Controller {
  static targets = [ 'input', 'result', 'preview' ]
  static values = { types: Array, server: String }

  connect() {
    this.inputTarget.classList.add('d-none')
    this.uppy = this.createUppy()
  }

  disconnect() {
    this.uppy.close()
  }

  createUppy() {
    const uppy = uppyInstance({
        id: this.inputTarget.id,
        types: this.typesValue,
        server: this.serverValue,
        maxNumFiles: 1,
      })
      .use(Uppy.Audio, {
        target: this.inputTarget.parentNode,
        showAudioSourceDropdown: false,
      })

    uppy.on('upload-success', (file, response) => {
      window.dispatchEvent(new CustomEvent('uppyToWavesurfer', {
        detail: {
          path: file.xhrUpload.endpoint + '/' + file.meta.key
        }
      }))

      const fileData = uploadedFileData(file, response, this.serverValue)

      const hiddenField = document.createElement('input')
      hiddenField.type = 'hidden'
      hiddenField.name = `segment[track]`
      hiddenField.value = fileData
      this.element.appendChild(hiddenField)
    })

    return uppy
  }
}
