//
// In-browser audio editing
// via https://github.com/pkalogiros/AudioMass
//
export function initAudioEditorUi(container) {
  if (document.querySelector(".pk_app")) return

  const audioMassEditor = PKAudioEditor.init(container.id)
  const buttonNext = document.querySelector("#trigger-process")
  const path = window.location.pathname.split("/")
  const token = document.querySelector('[name="authenticity_token"]').value

  function convert(n) {
    const v = n < 0 ? n * 32768 : n * 32767
    return Math.max(-32768, Math.min(32768, v))
  }

  function getUploadS3(filename) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      const url = "/s3/params?filename=" + filename + "&type=audio/mpeg"
      xhr.open('GET', url, true);
      xhr.responseType = 'application/json';
      xhr.onload = function () {
        if (this.status > 199 && this.status < 205) {
          resolve(JSON.parse(this.response))
        } else {
          reject(this.status)
        }
      };
      xhr.send();
    })
  }

  function postToS3(params, data, filename) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      for (const field in params.fields) {
        formData.append(field, params.fields[field])
      }
      formData.append('file', data, filename)
      const xhr = new XMLHttpRequest();
      xhr.open('POST', params.url, true);
      xhr.onload = function () {
        if (this.status > 199 && this.status < 205) {
          resolve()
        } else {
          reject(this.status)
        }
      };
      xhr.send(formData);
    })
  }

  function postToVersions(url, params) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      const formData = new FormData()
      for (const field in params) {
        formData.append(field, params[field])
      }
      xhr.open('POST', url, true);
      xhr.onload = function () {
        // NOTE: this route returns a redirect
        if (this.status > 199 && this.status < 305) {
          resolve()
        } else {
          reject(this.status)
        }
      };
      xhr.send(formData);
    })
  }

  async function processFileData(fileData) {
    const filename = "audio-" + Date.now() + ".mp3"

    try {
      // use xhr to get a signed url
      const signedParams = await getUploadS3(filename)
      // get the file path
      const filePath = signedParams.url + "/" + signedParams.fields.key
      // uplaod the file to S3
      await postToS3(signedParams, fileData, filename)
      // return the uploaded file path
      return filePath
    } catch (e) {
      console.error(e)
    }
  }

  function buildFileData() {
    return new Promise((resolve, reject) => {
      const wavesurfer = window.WaveSurferAudioMassApp

      var kbps = 128;
      var stereo = container.dataset["stereo"] === "true";

      var worker = new Worker(container.dataset["worker"])

      var originalBuffer = wavesurfer.backend.buffer;
      var sample_rate = originalBuffer.sampleRate;

      var channels = originalBuffer.numberOfChannels;

      var data_left = originalBuffer.getChannelData(0);
      var data_right = null;
      if (channels === 2)
        data_right = originalBuffer.getChannelData(1);

      if (!stereo && channels === 2) {
        if (!wavesurfer.ActiveChannels[0] && wavesurfer.ActiveChannels[1]) {
          data_left = originalBuffer.getChannelData(1);
          data_right = null;
          channels = 1;
        }
      }

      if (stereo && !data_right) {
        data_right = data_left;
        channels = 2;
      } else if (!stereo && data_right) {
        data_right = null;
        channels = 1;
      }

      var len = data_left.length, i = 0;
      var offset = 0;
      var region = wavesurfer.regions.list[0];
      var selection = !!region ? [region.start, region.end] : null;

      if (selection) {
        offset = (selection[0] * sample_rate) >> 0;
        len = ((selection[1] * sample_rate) >> 0) - offset;
      }

      var dataAsInt16ArrayLeft = new Int16Array(len);
      var dataAsInt16ArrayRight = null;

      if (data_right) {
        dataAsInt16ArrayRight = new Int16Array(len);
        while (i < len) {
          dataAsInt16ArrayLeft[i] = convert(data_left[offset + i]);
          dataAsInt16ArrayRight[i] = convert(data_right[offset + i]);
          ++i;
        }
      } else {
        while (i < len) {
          dataAsInt16ArrayLeft[i] = convert(data_left[offset + i]);
          ++i;
        }
      }

      worker.onmessage = function (ev) {
        if (ev.data.percentage) return;
        resolve(ev.data);
        worker.terminate();
        worker = null;
      }

      worker.postMessage({
        sample_rate: sample_rate,
        kbps: !kbps ? 128 : kbps,
        channels: channels
      });
      worker.postMessage(dataAsInt16ArrayLeft.buffer, [dataAsInt16ArrayLeft.buffer]);
      if (data_right) {
        worker.postMessage(dataAsInt16ArrayRight.buffer, [dataAsInt16ArrayRight.buffer]);
      } else {
        worker.postMessage(null);
      }
    })
  }

  // processing & uploading
  buttonNext.onclick = async () => {
    const url = "/" + path.slice(1, -1).join("/")
    buttonNext.disabled = true

    try {
      const fileData = await buildFileData()

      const filePath = await processFileData(fileData)

      await postToVersions(
        url + "/versions",
        {
          "authenticity_token": token,
          "version[uploaded_audio_url]": filePath,
          "organization_slug": path[1],
          "segment_id": path[3],
        }
      )

      setTimeout(() => {
        window.location.href = url + "/edit"
      }, 1000)
    } catch (e) {
      console.error(e)
    }
  }

  // enable the button, just in case
  buttonNext.disabled = false

  // init: load the current segment into the editor
  audioMassEditor.engine.LoadURL(container.dataset["audio"])
}