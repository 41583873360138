//
// Segment Splitting/Editing
//

// Segment Splitting
export function initSegmentSplitAndEdit(container) {
  const uiZoomOut = document.querySelector('#control-zoom-out')
  const uiZoomIn = document.querySelector('#control-zoom-in')
  const uiPlay = document.querySelector('#control-playpause')
  const uiAddSplit = document.querySelector('#control-add-split')
  const uiRemoveSplits = document.querySelector('#control-remove-split')
  const inputTimings = document.querySelector('input#timings')
  const regionParams = {
    color: 'rgba(40, 255, 70, 0.3)',
    loop: false,
    minLength: 0.1,
  }
  let playbackInterval = null
  let tmpRegion = null
  let zoomLevel = 0

  // Setup for Rails
  inputTimings.value = JSON.stringify([])

  // Waveforms
  const wavesurfer = window.WaveSurferSpooler.create({
    container: '#control-waveform',
    cursorColor: '#000',
    cursorWidth: 2,
    height: 180,
    waveColor: '#3E8BB7',
    progressColor: '#305677',
    plugins: [
      window.RegionsPlugin.create({}),
      window.TimelinePlugin.create({
        container: '#control-timeline',
        formatTimeCallback: (time) => {
          return window.secToTimestamp(time)
        }
      }),
    ],
    responsive: true,
  })

  wavesurfer.enableDragSelection({
    ...regionParams
  })

  // Math stuff for region timings
  const calculateRegionTimings = () => {
    const outputTimings = []
    let allRegions = []
    let trimRegions = []

    // arrange all regions
    for (let i in wavesurfer.regions.list) {
      const r = wavesurfer.regions.list[i]
      trimRegions.push([r.start, r.end])
    }

    // sort trims by start times
    if (trimRegions.length > 0) {
      const tmp = []

      // sort by start times
      trimRegions.sort((a, b) => a[0] - b[0])

      // iterate through and combine overlaps
      for (let i of trimRegions) {
        const prev = tmp.at(-1)
        if (!tmp.length || prev[1] < i[0]) {
          tmp.push(i)
        } else {
          prev[1] = Math.max(prev[1], i[1])
        }
      }

      // flatten
      trimRegions = tmp.flat()

      // push all the timings into the main array
      allRegions = Array.prototype.concat([], trimRegions)
    }

    // split the in/out markers array into sub-arrays
    const size = 2
    for (let i = 0; i < allRegions.length; i += size) {
      const chunk = allRegions.slice(i, i + size)
      outputTimings.push(chunk)
    }

    // hidden inputs
    inputTimings.value = JSON.stringify(outputTimings)
  }

  // events
  wavesurfer.on('region-created', window.debounce(() => {
    calculateRegionTimings()
  }, 200))

  wavesurfer.on('region-removed', () => {
    calculateRegionTimings()
  })

  wavesurfer.on('region-dblclick', (r) => {
    r.remove()
  })

  wavesurfer.on('region-updated', window.debounce(() => {
    calculateRegionTimings()
  }, 50))

  // playback
  wavesurfer.on('play', () => {
    uiPlay.setAttribute('data-playing', 'true')
  })

  wavesurfer.on('pause', () => {
    uiPlay.setAttribute('data-playing', 'false')
    clearInterval(playbackInterval)
  })

  // controls
  uiZoomOut.addEventListener('click', () => {
    zoomLevel = zoomLevel - 50
    wavesurfer.zoom(zoomLevel)
  })
  uiZoomIn.addEventListener('click', () => {
    zoomLevel = zoomLevel + 50
    wavesurfer.zoom(zoomLevel)
  })

  uiPlay.addEventListener('click', () => {
    if (wavesurfer.isPlaying()) {
      wavesurfer.pause()
    } else {
      wavesurfer.play()
    }
  })

  uiAddSplit.addEventListener('click', () => {
    const now = wavesurfer.getCurrentTime()
    const then = wavesurfer.getDuration() / 10
    wavesurfer.addRegion({
      ...regionParams,
      start: now,
      end: now + then,
    })
  })

  uiRemoveSplits.addEventListener('click', () => {
    inputTimings.value = ''
    wavesurfer.clearRegions()
  })

  // keyboard shortcuts
  document.addEventListener('keyup', (e) => {
    if (!document.getElementById(container.id)) return
    console.log(container.id)

    if (e.code.includes('Space')) {
      // spacebar: play/pause
      e.preventDefault()
      if (wavesurfer.isPlaying()) {
        wavesurfer.pause()
      } else {
        wavesurfer.play()
      }
    } else if (e.key === 'i') {
      // i: add region
      const now = wavesurfer.getCurrentTime()
      tmpRegion = null
      tmpRegion = wavesurfer.addRegion({
        ...regionParams,
        start: now,
        end: now + 0.1,
      })
    } else if (e.key === 'o' && tmpRegion) {
      // o: end region
      for (let i in wavesurfer.regions.list) {
        const r = wavesurfer.regions.list[i]
        if (r.id === tmpRegion.id) {
          r.update({
            end: wavesurfer.getCurrentTime()
          })
        }
      }
    } else if (e.key === 'ArrowLeft') {
      // left arrow: rewind
      wavesurfer.skipBackward(5)
    } else if (e.key === 'ArrowRight') {
      // right arrow: fast forward
      wavesurfer.skipForward(5)
    } else if (e.key === 'ArrowUp') {
      // up arrow: zoom in
      zoomLevel = zoomLevel + 50
      wavesurfer.zoom(zoomLevel)
    } else if (e.key === 'ArrowDown') {
      // down arrow: zoom out
      zoomLevel = zoomLevel - 50
      wavesurfer.zoom(zoomLevel)
    }
  })

  // init
  wavesurfer.on('ready', function () {
    container.classList.remove('loading')
  })
  wavesurfer.load(container.dataset.audio)
}