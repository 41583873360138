// Sortable setup tasks
export function editionsSortableListsSetup() {

  //
  // SEGMENTS
  //
  $("#segments").sortable({
    cursor: "move",
    connectWith: "#segments, #selected-segments"
  }).disableSelection()

  //
  // STINGERS
  //
  $("#stingers").sortable({
    cursor: "move",
    connectWith: "#stingers, #selected-segments",
    helper: function (e, div) {
      this.copyHelper = div.clone().insertAfter(div);
      $(this).data('copied', false);
      return div.clone();
    },
    stop: function (e, ui) {
      var copied = $(this).data('copied');
      if (!copied) {
        this.copyHelper.remove();
      }

      ui.item.removeClass('badge');
      ui.item.addClass('stinger-segment');
      ui.item.addClass('card');
      this.copyHelper = null;
    }
  });

  //
  // AD LOCATION POINTS
  //
  $("#ad-location-points").sortable({
    cursor: "move",
    connectWith: "#ad-location-points, #selected-segments",
    helper: function (e, div) {
      this.copyHelper = div.clone().insertAfter(div);
      $(this).data('copied', false);
      return div.clone();
    },
    stop: function (e, ui) {
      var copied = $(this).data('copied');
      if (!copied) {
        this.copyHelper.remove();
      }

      ui.item.removeClass('badge');
      ui.item.addClass('ad-location-point-segment');
      ui.item.addClass('card');
      this.copyHelper = null;
    }
  });

  //
  // LOOPS
  //
  $("#loops").sortable({
    cursor: "move",
    connectWith: "#loops, #selected-segments",
    helper: function (e, div) {
      this.copyHelper = div.clone().insertAfter(div);
      $(this).data('copied', false);
      return div.clone();
    },
    stop: function (e, ui) {
      var copied = $(this).data('copied');
      if (!copied) {
        this.copyHelper.remove();
      }

      ui.item.removeClass('badge');
      ui.item.addClass('loop-segment');
      ui.item.addClass('card');
      this.copyHelper = null;
    }
  });

  //
  // THEMES
  //
  $("#themes").sortable({
    cursor: "move",
    connectWith: "#themes, #selected-segments",
    helper: function (e, div) {
      this.copyHelper = div.clone().insertAfter(div);
      $(this).data('copied', false);
      return div.clone();
    },
    stop: function (e, ui) {
      var copied = $(this).data('copied');
      if (!copied) {
        this.copyHelper.remove();
      }

      ui.item.removeClass('badge');
      ui.item.addClass('loop-segment');
      ui.item.addClass('card');
      this.copyHelper = null;
    }
  });

  //
  // SILENCE
  //
  $("#silence").sortable({
    cursor: "move",
    connectWith: "#silence, #selected-segments",
    helper: function (e, div) {
      this.copyHelper = div.clone().insertAfter(div);
      $(this).data('copied', false);
      return div.clone();
    },
    stop: function (e, ui) {
      var copied = $(this).data('copied');
      if (!copied) {
        this.copyHelper.remove();
      }

      ui.item.removeClass('badge');
      ui.item.addClass('loop-segment');
      ui.item.addClass('card');
      this.copyHelper = null;
    }
  });

  //
  // PLAYLIST ONLY
  //
  $("#selected-segments").sortable({
    cursor: "move",
    receive: function (e, ui) {
      ui.sender.data('copied', true);
    },
    update: function (event, ui) {
      postPlaylistChanges()
    },
  });

  //
  // Calculate timings
  //
  calculatePlaylistTimings()
}

// Removing sortable playlist items via delete action(s)
export function removePlaylistItem(el = null) {
  if (!el) return

  // if this is a segment, move it back to the main list
  const $item = $(el).parents('.card')
  if ($item.attr('data-kind') === 'segment') {
    $item.appendTo('#segments')
  } else {
    $item.remove()
  }

  // post changes to the server
  postPlaylistChanges()
}

// Post the list of ordered segments to the server
function postPlaylistChanges() {
  // Calculate timings
  calculatePlaylistTimings()

  // Set the loading status
  $('#saving-status').removeClass('success fail').addClass('loading');

  // This could also be done with serialize( options ) or toArray( options )
  // var sortedIDs = $(".selector").sortable( "toArray" );
  var unit_ids = [];
  $('#selected-segments').children('div').each(function () {
    unit_ids.push(this.id);
  });

  // Send to server
  $.ajax({
    url: $('#editions-playlist-page').attr('data-move-url'),
    dataType: 'json',
    type: 'post',
    cache: false,
    processData: true,
    data: { unit_ids: unit_ids },
    success: function (response) {
      if (response.result == 1) {
        setTimeout(function () {
          $('#saving-status').removeClass('loading').addClass('success');
        }, 500);
      } else {
        $('#saving-status').removeClass('loading').addClass('fail');
      }
    },
    error: function (error) {
      $('#saving-status').removeClass('loading').addClass('fail');
    },
  });
}

// Calculate timings for all playlist items
function calculatePlaylistTimings() {
  var time = 0;
  $('#selected-segments div').each(function () {
    if (this.dataset.duration) {
      time += parseInt(this.dataset.duration)
    }
  });
  $('#playlist-duration').text(window.secToTimestamp(time));
}
