// Audio tools
require("./audiomass/oneup.js")
require("./audiomass/app.js")
require("./audiomass/keys.js")
require("./audiomass/contextmenu.js")
require("./audiomass/ui-fx.js")
require("./audiomass/ui.js")
require("./audiomass/modal.js")
require("./audiomass/state.js")
require("./audiomass/engine.js")
require("./audiomass/actions.js")
require("./audiomass/drag.js")
require("./audiomass/recorder.js")
require("./audiomass/fx-pg-eq.js")
require("./audiomass/fx-auto.js")
require("./audiomass/local.js")
require("./audiomass/id3.js")

window.WaveSurferSpooler = require("wavesurfer.js")
window.RegionsPlugin = require("wavesurfer.js/dist/plugin/wavesurfer.regions.min.js")
window.TimelinePlugin = require("wavesurfer.js/dist/plugin/wavesurfer.timeline.min.js")
window.WaveSurferAudioMass = require("./audiomass/wavesurfer.js")
window.WaveSurferAudioMassRegions = require("./audiomass/wavesurfer.regions.js")

const { initAudioEditorUi } = require('./audio-editor')
const { editionsSortableListsSetup, removePlaylistItem } = require('./editions-playlist')
const { initSegmentSplitAndEdit } = require('./segment-splitting')
const { initSegmentRecordAndEdit } = require('./single-recording')

// const segmentTranscriptions = document.querySelector('#segment-transcription')
// const transcriptEditor = document.querySelector('#transcript-editor-ui')

// Audio players
window.Plyr = require("plyr")
let PlyrEl

// page loads via turbo
document.addEventListener('turbolinks:load', function () {
  let container = null

  //
  // Audio players: re-create all players just to be safe
  //
  if (PlyrEl) PlyrEl.destroy()
  PlyrEl = new window.Plyr(document.querySelector('.js-player'))

  //
  // Audio Editor
  //
  container = document.querySelector('#audio-editor-ui')
  if (container) {
    initAudioEditorUi(container)
  }

  //
  // Segment Splitting
  //
  container = document.querySelector('#audio-editing-segment-page')
  if (container) {
    initSegmentSplitAndEdit(container)
  }

  //
  // Segment Recording
  //
  container = document.querySelector('#audio-record-segment-page')
  if (container) {
    initSegmentRecordAndEdit(container)

    let autoProceedCheck = null

    const autoProceedToWavesurfer = () => {
      document.querySelector('.uppy-Audio-button--submit').click()
      clearInterval(autoProceedCheck)
    }

    autoProceedCheck = setInterval(() => {
      if (document.querySelector('.uppy-Audio-button--submit')) {
        autoProceedToWavesurfer()
      }
    }, 1000)
  }

  //
  // Transcription Generation
  //
  // if (segmentTranscriptions) {
  //   const initSegmentTranscriptions = require('./transcript-generation')
  //   initSegmentTranscriptions(segmentTranscriptions)
  // }

  //
  // Transcript Editor
  //
  // if (transcriptEditor && audioEditorUi) {
  //   const initTranscriptEditor = require('./transcript-editor')
  //   initTranscriptEditor(transcriptEditor)
  // }

  //
  // Edition Segments (sortable lists)
  //
  container = document.querySelector('#editions-playlist-page')
  if (container) {
    // Init
    editionsSortableListsSetup()

    // Changes to the Segments list based on searches
    new MutationObserver(function (mutationsList, observer) {
      editionsSortableListsSetup()
    }).observe(
      document.querySelector('#segments-section'),
      { childList: true }
    )

    // Library dropdown changes
    $('#library-menu').on('change', function (e) {
      var selected = $("option:selected", this).val().toLowerCase().trim()
      $('#library-contents-wrap .tab-pane').each(function () {
        var tab = $(this)
        if (tab.is('#' + selected + '-section')) {
          tab.show()
        } else {
          tab.hide()
        }
      })
    })
  }
})

// Top-level listener for click events
document.addEventListener('click', function (event) {
  const el = event.target

  if (!el.className || typeof el.className.includes !== 'function') return

  //
  // Audio player events
  //

  // Play events
  if (el.className.includes('play-circle') || el.className.includes('play-button')) {
    // detect proper src
    const src = el.className.includes('play-circle')
      ? el.parentNode.attributes['data-src'].value
      : el.attributes['data-src'].value

    // pause everything
    PlyrEl.pause()

    // load the source
    PlyrEl.source = {
      type: 'audio',
      sources: [
        {
          src,
          type: 'audio/mp3',
        }
      ],
    }

    // play it
    PlyrEl.play()
  }

  // Stop events
  if (el.className.includes('stop-circle') || el.className.includes('stop-button')) {
    PlyrEl.pause()
  }

  //
  // Playlist events
  //

  // Move a playlist item (segment, loop, etc.) back to its list
  if (el.className.includes('delete-action-button') || (el.className.includes('fa-trash') && el.parentNode.classList.contains('delete-action-button'))) {
    removePlaylistItem(el)
  }

}, false)

// Util
window.debounce = (func, wait, immediate) => {
  var timeout
  return function () {
    var context = this, args = arguments
    var later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    var callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

window.throttle = (fn, delay) => {
  let timeout = false
  return (...rest) => {
    if (!timeout) {
      timeout = true
      fn.apply(this, rest)
      setTimeout(() => {
        timeout = false
      }, delay)
    }
  }
}

window.secToTimestamp = (t) => {
  let time = ''
  if (isNaN(t)) return '--:--'
  const h = parseInt(t / 3600)
  const m = parseInt(t / 60) % 60
  const s = parseInt(t % 60)
  if (h > 0) time += ('00' + h).slice(-2) + ':'
  time += ('00' + m).slice(-2) + ':' + ('00' + s).slice(-2)
  return time
}

// Tooltips
$(function () {
  $('[data-toggle="tooltip"]').tooltip()
})

// Timestamps
$.fn.setNow = function (onlyBlank) {
  var now = new Date($.now());

  var year = now.getFullYear();

  var month = (now.getMonth() + 1).toString().length === 1 ? '0' + (now.getMonth() + 1).toString() : now.getMonth() + 1;
  var date = now.getDate().toString().length === 1 ? '0' + (now.getDate()).toString() : now.getDate();
  var hours = now.getHours().toString().length === 1 ? '0' + now.getHours().toString() : now.getHours();
  var minutes = now.getMinutes().toString().length === 1 ? '0' + now.getMinutes().toString() : now.getMinutes();
  var seconds = now.getSeconds().toString().length === 1 ? '0' + now.getSeconds().toString() : now.getSeconds();

  var formattedDateTime = year + '-' + month + '-' + date + 'T' + hours + ':' + minutes + ':' + seconds;

  if (onlyBlank === true && $(this).val()) {
    return this;
  }

  $(this).val(formattedDateTime);

  return this;
}